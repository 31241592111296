<script lang="ts" setup>
import useProcessObservers, { isProcessSaveArgs } from '../../composables/useProcessObservers';
import IssueInformationEdit from '@/features/issues/components/issueCreate/IssueInformationEdit.vue';
import { computed, onMounted, ref } from 'vue';
import { IssueContact, useDeleteFileMutation } from '@/generated/graphql';
import useActiveIssueOperations from '../../composables/useActiveIssueOperations';
import { useProcessStore, ProcessOperation } from '@/features/issueProcess/composables/useProcessStore';
import { Issue, IssueDetails } from '@/features/issues/models';
import { useI18n } from 'vue-i18n';
import { UpdateIssueInput } from '../../models/UpdateIssueInput';
import { Delta } from '@vueup/vue-quill';
import useIssueContacts from '@/features/issues/composables/useIssueContacts';
import useGeoJson from '@/features/map/composables/useGeoJson';
import useProjections from '@/features/map/composables/useProjections';
import useIssueFeatures from '@/features/issues/composables/useIssueFeatures';
import { IssueContactOp } from '@/features/issues/models/IssueContactOp';

const props = defineProps<{
  activeIssue: IssueDetails;
}>();

const information = ref<UpdateIssueInput>({
  title: '',
  description: '',
  communityIds: [],
  files: [],
  skippedPhaseIds: [],
  hasCommunitiesMapGeometries: false,
});

const { executeMutation: deleteFile } = useDeleteFileMutation();
const deletedFileIds = ref<string[]>([]);

const { t } = useI18n();
const issueValidationErrors = ref<Errors<UpdateIssueInput>>({});
const validateInput = (input: UpdateIssueInput): Errors<UpdateIssueInput> => {
  const errors: Errors<UpdateIssueInput> = {};

  if (!input.title || input.title.length <= 0) {
    errors.title = [t('validation.required', { attribute: t('issue.title') })];
  }

  if (!input.quillDelta?.ops?.length || input.quillDelta.ops.every((op) => !op.insert?.toString().trim())) {
    errors.description = [t('validation.required', { attribute: t('issue.description') })];
  }

  if (!input.communityIds || input.communityIds.length <= 0) {
    errors.communityIds = [t('validation.min', { attribute: t('base.community'), min: 1 })];
  }

  if (!input.geometries?.geoJson) {
    errors.geometries = [t('validation.required', { attribute: t('issue.geometries') })];
  }

  return errors;
};
const validate = () => {
  issueValidationErrors.value = validateInput(information.value);
  return Object.keys(issueValidationErrors.value).length === 0;
};

const { updateActiveIssueInfo, addRelatedIssueToActiveIssue, removeRelatedIssueToActiveIssue } = useActiveIssueOperations(computed(() => props.activeIssue.id));
const issueContactsInput = ref<Partial<IssueContact>[]>([...props.activeIssue.contacts]);
const issueContacts = computed(() => (issueContactsInput.value ? issueContactsInput.value : props.activeIssue.contacts));

const onUpdateIssueContacts = (contacts: Partial<IssueContact>[]) => {
  issueContactsInput.value = contacts;
};

const onUpdateIssueContact = (op: IssueContactOp, contact: Partial<IssueContact>) => {
  switch (op) {
    case IssueContactOp.Add:
      issueContactsInput.value = [...issueContactsInput.value, { ...contact }];
      break;
    case IssueContactOp.Update:
      issueContactsInput.value = issueContactsInput.value.map((ic) => {
        return ic.id === contact.id ? { ...contact } : ic;
      });
      break;
    case IssueContactOp.Remove:
      issueContactsInput.value = issueContactsInput.value.filter((ic) => ic.id !== contact.id);
      break;
    default:
      console.error('IssueContactOp Not Found:', op);
      break;
  }
};

const { addContactToIssue, removeContactFromIssue, refreshAvailableIssueContactsQuery } = useIssueContacts(props.activeIssue.id.toString());

const updateIssueContacts = async (contacts: Partial<IssueContact>[]) => {
  const contactsToRemove = props.activeIssue.contacts.filter((c) => !contacts.some(({ name, email }) => c.name === name && c.email === email));

  for (const c of contactsToRemove) {
    if (c.id) {
      await removeContactFromIssue(c.id.toString(), props.activeIssue.id.toString());
    }
  }

  const contactsToAdd = contacts.filter((c) => !props.activeIssue.contacts.some(({ name, email }) => c.name === name && c.email === email));

  for (const c of contactsToAdd) {
    const { email, name } = c;
    if (email && name) {
      await addContactToIssue({ email, name }, props.activeIssue.id.toString());
    }
  }

  refreshAvailableIssueContactsQuery({ requestPolicy: 'cache-and-network' });
};

const saveIssueInformation = async () => {
  if (!validate()) {
    throw new Error(t('validation.submit'));
  }

  for (const fileId of deletedFileIds.value) {
    await deleteFile({ fileId });
  }

  if (issueContactsInput.value) {
    await updateIssueContacts(issueContactsInput.value);
  }

  await updateActiveIssueInfo(information.value);
  information.value.files = [];
};
const onProcessStep = async () => {
  await saveIssueInformation();
};

const onProcessSave = async (o: unknown) => {
  if (isProcessSaveArgs(o) && o.autosave) {
    return;
  }

  await saveIssueInformation();
};

useProcessObservers({ [ProcessOperation.Step]: [onProcessStep], [ProcessOperation.Save]: [onProcessSave] });

const onAddRelatedIssue = (issueId: string) => {
  addRelatedIssueToActiveIssue(issueId);
};

const onRemoveRelatedIssue = (issue: Issue) => {
  removeRelatedIssueToActiveIssue(issue.id);
};

const { featuresToGeoJson } = useGeoJson();
const { transformFeaturesIsToWgs } = useProjections();
const { getIssueFeatures } = useIssueFeatures();

onMounted(() => {
  const { richTextDescription: maybeDelta, description } = props.activeIssue;

  let ops = [];
  if (maybeDelta) {
    ops = JSON.parse(maybeDelta);
  } else if (description) {
    ops.push({ insert: description });
  }

  let geometries = null;
  if (props.activeIssue.geographies?.features?.length) {
    const issueFeatures = getIssueFeatures(props.activeIssue);
    geometries = {
      geoJson: featuresToGeoJson(transformFeaturesIsToWgs(issueFeatures)),
    };
  }

  information.value = {
    title: props.activeIssue.title || '',
    description: props.activeIssue.description || '',
    communityIds: props.activeIssue.communities?.map(({ id }) => id.toString()) || [],
    tags: props.activeIssue.tags,
    skippedPhaseIds: props.activeIssue.phases.filter((p) => p.isOptional && p.shouldSkip).map((p) => p.id.toString()),
    quillDelta: new Delta(ops),
    hasCommunitiesMapGeometries: props.activeIssue.hasCommunitiesMapGeometries,
    geometries,
  };
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

const onErrorClear = (field: keyof UpdateIssueInput): void => {
  delete issueValidationErrors.value[field];
};

const onErrorSet = (field: keyof UpdateIssueInput, message: string): void => {
  issueValidationErrors.value[field] = [message];
};

const processStore = useProcessStore();

const onInformationUpdate = (input: UpdateIssueInput, isDirty: boolean) => {
  information.value = input;
  processStore.isDirty = processStore.isDirty || isDirty;
};
</script>

<template>
  <IssueInformationEdit
    v-if="activeIssue"
    :issue="activeIssue"
    :information="information"
    v-model:deleted="deletedFileIds"
    :errors="issueValidationErrors"
    :issue-contacts="issueContacts"
    @update:issue-contacts="onUpdateIssueContacts"
    @update:issue-contact="onUpdateIssueContact"
    @add:related="onAddRelatedIssue"
    @remove:related="onRemoveRelatedIssue"
    @clear:error="onErrorClear"
    @set:error="onErrorSet"
    @update:information="onInformationUpdate"
  />
</template>
